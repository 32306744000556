import { lazy } from "react";
import UnitLogList from "../Pages/ListUnitLog/UnitLogList.page.jsx";

const Home = lazy(() => import("../Pages/Home/home.page"));

/*** division ***/
const SubscriptionsList = lazy(() =>
  import("../Pages/ListSubscriptions/SubscriptionsList.page")
);
const PublicSubscription = lazy(() =>
  import("../Pages/RegisterSubscription/PublicSubscription.page")
);
const yourSubscription = lazy(() =>
  import("../Pages/yourSubscription/YourSubscription.page")
);
const selectPackages = lazy(() =>
  import("../Pages/yourSubscription/SelectPackage.page.jsx")
);

const RegisterSubscription = lazy(() =>
  import("../Pages/RegisterSubscription/RegisterSubscription.page")
);
const EditionSubscription = lazy(() =>
  import("../Pages/RegisterSubscription/EditionSubscription.page")
);
const ShowSubscription = lazy(() =>
  import("../Pages/RegisterSubscription/ShowSubscription.page")
);

/**** contacts ****/
const ContactsList = lazy(() =>
  import("../Pages/ListContacts/ContactsList.page")
);

const ContactLog = lazy(() =>
  import("../Pages/ListContactLog/ContactLogList.page.jsx")
);

const FavoriteUnitContact = lazy(() =>
  import("../Pages/ListContacts/FavoriteUnitContact.page")
);

const RegisterContact = lazy(() =>
  import("../Pages/RegisterContact/RegisterContact.page")
);
const EditionContact = lazy(() =>
  import("../Pages/RegisterContact/EditionContact.page")
);
const ShowContact = lazy(() =>
  import("../Pages/RegisterContact/ShowContact.page")
);
/*** users ***/
const UsersList = lazy(() => import("../Pages/ListUser/UsersList.page"));

const UserLog = lazy(() => import("../Pages/ListUserLog/UserLogList.page.jsx"));

const EditionUser2 = lazy(() =>
  import("../Pages/RegisterProfile/EditionUser.page")
);
const PasswordsList = lazy(() =>
  import("../Pages/ListUser/PasswordsList.page")
);
const CommitionsList = lazy(() =>
  import("../Pages/ListUser/CommitionsList.page")
);
const UserContractList = lazy(() =>
  import("../Pages/ListUser/ContractsList.page.jsx")
);
const RegisterUser = lazy(() =>
  import("../Pages/RegisterUser/RegisterUser.page")
);
const EditionUser = lazy(() =>
  import("../Pages/RegisterUser/EditionUser.page")
);

const ShowUser = lazy(() => import("../Pages/RegisterUser/ShowUser.page"));
const LoginLogsUser = lazy(() =>
  import("../Pages/ListUser/LoginLogsUser.page.jsx")
);

const RegisterPassword = lazy(() =>
  import("../Pages/RegisterUser/RegisterPassword.page")
);
const RegisterSchedule = lazy(() =>
  import("../Pages/RegisterUser/RegisterSchedule.page")
);
const EditSchedule = lazy(() =>
  import("../Pages/RegisterUser/EditSchedule.page")
);

/*** items ***/
const ItemsList = lazy(() => import("../Pages/ListUnits/UnitsList.page"));
const ItemsLog = lazy(() =>
  import("../Pages/ListUnitLog/UnitLogList.page.jsx")
);
const RegisterItem = lazy(() =>
  import("../Pages/RegisterUnit/RegisterUnit.page")
);
const EditionItem = lazy(() =>
  import("../Pages/RegisterUnit/EditionUnit.page")
);
const ShowItem = lazy(() => import("../Pages/RegisterUnit/ShowUnit.page"));
const PriceChart = lazy(() => import("../Pages/Charts/PriceChart.page"));
const PriceHistoriesList = lazy(() =>
  import("../Pages/ListUnits/PriceHistoriesList.page")
);
const ChangePriceUnit = lazy(() =>
  import("../Pages/RegisterUnit/ChangePriceUnit.page")
);

/*** extra fields ***/
const ExtraFieldsList = lazy(() =>
  import("../Pages/ListExtraFields/ExtraFieldsList.page")
);

const UsedsList = lazy(() => import("../Pages/ListExtraFields/UsedList.page"));

const RegisterExtraField = lazy(() =>
  import("../Pages/RegisterExtraField/RegisterExtraField.page")
);
const EditionExtraField = lazy(() =>
  import("../Pages/RegisterExtraField/EditionExtraField.page")
);
const ShowExtraField = lazy(() =>
  import("../Pages/RegisterExtraField/ShowExtraField.page")
);
/*** types ***/
const TypesList = lazy(() => import("../Pages/ListTypes/TypesList.page"));
const RegisterType = lazy(() =>
  import("../Pages/RegisterType/RegisterType.page")
);
const EditionType = lazy(() =>
  import("../Pages/RegisterType/EditionType.page")
);
const ShowType = lazy(() => import("../Pages/RegisterType/ShowType.page"));
/*** languages ***/
const LanguagesList = lazy(() =>
  import("../Pages/ListLanguages/LanguagesList.page")
);

const RegisterLanguage = lazy(() =>
  import("../Pages/RegisterLanguage/RegisterLanguage.page")
);
const EditionLanguage = lazy(() =>
  import("../Pages/RegisterLanguage/EditionLanguage.page")
);
const ShowLanguage = lazy(() =>
  import("../Pages/RegisterLanguage/ShowLanguage.page")
);

/*** projects ***/
const ProjectsList = lazy(() =>
  import("../Pages/ListProject/ProjectsList.page")
);
const RegisterProject = lazy(() =>
  import("../Pages/RegisterProject/RegisterProject.page")
);
const EditionProject = lazy(() =>
  import("../Pages/RegisterProject/EditionProject.page")
);
const ShowProject = lazy(() =>
  import("../Pages/RegisterProject/ShowProject.page")
);
const ChangeProjectAgent = lazy(() =>
  import("../Pages/RegisterProject/ChangeProjectAgent.page")
);
/*** reservation ***/
const ReservationList = lazy(() =>
  import("../Pages/ListReservation/ReservationList.page")
);
const ReservationUnitList = lazy(() =>
  import("../Pages/ListReservation/ReservationUnitList.page")
);
const RegisterReservation = lazy(() =>
  import("../Pages/RegisterReservation/RegisterReservation.page")
);
const EditionReservation = lazy(() =>
  import("../Pages/RegisterReservation/EditionReservation.page")
);
const ShowContractForm = lazy(() =>
  import("../Pages/RegisterReservation/ShowContractForm.page")
);
const RegisterPayment = lazy(() =>
  import("../Pages/RegisterReservation/RegisterPayment.page")
);
const RegisterCommitions = lazy(() =>
  import("../Pages/RegisterReservation/RegisterCommitions.page")
);
const RegisterContactForm = lazy(() =>
  import("../Pages/RegisterReservation/RegisterContactForm.page")
);

/*** reservation ***/
const UserGroupsList = lazy(() =>
  import("../Pages/ListUserGroups/UserGroupsList.page")
);
const RegisterUserGroup = lazy(() =>
  import("../Pages/RegisterUserGroup/RegisterRole.page")
);

const EditionRole = lazy(() =>
  import("../Pages/RegisterUserGroup/EditionRole.page")
);
const ShowRole = lazy(() => import("../Pages/RegisterUserGroup/ShowRole.page"));

/*** contracts ***/
const ContractsList = lazy(() =>
  import("../Pages/ListContracts/ContractsList.page")
);
const ContractsCommissionsList = lazy(() =>
  import("../Pages/ListContracts/ContractCommissionsList.page.jsx")
);
/*** negotiates ***/
const NegotiatesList = lazy(() =>
  import("../Pages/ListNegotiates/NegotiatesList.page")
);
const NegotiatesContactList = lazy(() =>
  import("../Pages/ListNegotiates/NegotiatesContactList.page")
);

const RegisterNegotiates = lazy(() =>
  import("../Pages/RegisterNegotiates/RegisterNegotiates.page")
);
const EditionNegotiates = lazy(() =>
  import("../Pages/RegisterNegotiates/EditionNegotiates.page")
);
const ShowNegotiates = lazy(() =>
  import("../Pages/RegisterNegotiates/ShowNegotiates.page")
);

/*** setting ***/
const RegisterSetting = lazy(() =>
  import("../Pages/RegisterSetting/RegisterSetting.page")
);
/*** file manager ***/
const RegisterFile = lazy(() =>
  import("../Pages/RegisterFileManager/RegisterFile.page")
);
/*** messages ***/
const MessagesList = lazy(() =>
  import("../Pages/ListMessages/MessagesList.page")
);
const NotificationsList = lazy(() =>
  import("../Pages/ListNotifications/NotificationList.page")
);
const ContactNotificationList = lazy(() =>
  import("../Pages/ListNotifications/ContactNotificationList.page")
);
const RegisterMessage = lazy(() =>
  import("../Pages/RegisterMessage/RegisterMessage.page")
);
const EditionMessage = lazy(() =>
  import("../Pages/RegisterMessage/EditionMessage.page")
);
const ShowMessage = lazy(() =>
  import("../Pages/RegisterMessage/ShowMessage.page")
);

/*** message template ***/

const MessageTemplateList = lazy(() =>
  import("../Pages/ListMessageTemplate/MessageTemplateList.page")
);

const RegisterMessageTemplate = lazy(() =>
  import("../Pages/RegisterMessageTemplate/RegisterMessageTemplate.page")
);

const EditionMessageTemplate = lazy(() =>
  import("../Pages/RegisterMessageTemplate/EditionMessageTemplate.page.jsx")
);

/*** tickets ***/
const TicketsList = lazy(() => import("../Pages/ListTickets/TicketsList.page"));
const RegisterTicket = lazy(() =>
  import("../Pages/RegisterTicket/RegisterTicket.page")
);
const ShowTicket = lazy(() =>
  import("../Pages/RegisterTicket/ShowTicket.page")
);

/*** Packages ***/
const PackagesList = lazy(() =>
  import("../Pages/ListPackages/PackagesList.page")
);

const RegisterPackage = lazy(() =>
  import("../Pages/RegisterPackage/RegisterPackage.page")
);
const RegisterGiftesPackage = lazy(() =>
  import("../Pages/RegisterPackage/RegisterGiftesPackage.page")
);

const EditionPackage = lazy(() =>
  import("../Pages/RegisterPackage/EditionPackage.page")
);
const ShowPackage = lazy(() =>
  import("../Pages/RegisterPackage/ShowPackage.page")
);

/*** feedbacks ***/
const ReceivedFeedbacksList = lazy(() =>
  import("../Pages/ListReceivedFeedbacks/ReceivedFeedbacksList.page")
);

const ShowReceivedFeedback = lazy(() =>
  import("../Pages/ListReceivedFeedbacks/ShowReceivedFeedback.page")
);

const FeedbacksList = lazy(() =>
  import("../Pages/ListFeedbacks/FeedbacksList.page")
);

const ShowFeedback = lazy(() =>
  import("../Pages/ListFeedbacks/ShowFeedback.page")
);

/*** call me ***/
const CallMeList = lazy(() => import("../Pages/ListCallMe/CallMeList.page"));
const ShowCallMe = lazy(() => import("../Pages/ListCallMe/ShowCallMe.page"));

/*** contact us ****/

const ContactUsList = lazy(() =>
  import("../Pages/ListContactUs/ContactUsList.page")
);
const ShowContactUs = lazy(() =>
  import("../Pages/ListContactUs/ShowContactUs.page")
);

/*** invoices ***/

const InvoicesList = lazy(() =>
  import("../Pages/ListInvoices/InvoicesList.page")
);
const RegisterInvoice = lazy(() =>
  import("../Pages/RegisterInvoice/RegisterInvoice.page")
);
const EditionInvoice = lazy(() =>
  import("../Pages/RegisterInvoice/EditionInvoice.page")
);
const ShowInvoice = lazy(() =>
  import("../Pages/RegisterInvoice/ShowInvoice.page")
);

/**** ****/

const VisitRequestsList = lazy(() =>
  import("../Pages/ListVisitRequest/vistRequestsList.page")
);

/*** contact adn items ***/

const ContactAndItemsList = lazy(() =>
  import("../Pages/ListContactAndItems/ContactAndItemsList.page")
);

const RegisterContactAndItem = lazy(() =>
  import("../Pages/RegisterContactAndItem/RegisterContactAndItem.page")
);

// Kanban
const KanbanBoard = lazy(() => import("../Pages/kanban"));

// deleted

const deletedUsersList = lazy(() =>
  import("../Pages/ListDeletedUsers/DeletedUsersList.page")
);

const deletedContactsList = lazy(() =>
  import("../Pages/ListDeletedContacts/DeletedContactsList.page")
);

const deletedUnitsList = lazy(() =>
  import("../Pages/ListDeletedUnits/DeletedUnitsList.page")
);

// form maker

const formMakerList = lazy(() =>
  import("../Pages/ListFormMaker/FormMakerList.page")
);
const Addform = lazy(() =>
  import("../Pages/RegisterForm/RegisterForm.page.jsx")
);
const EditForm = lazy(() => import("../Pages/RegisterForm/EditForm.page.jsx"));
const SeeForm = lazy(() => import("../Pages/RegisterForm/SeeForm.page.jsx"));

// add house

const addHouseList = lazy(() => import("../Pages/ListHouse/HouseList.page"));

const registerHouse = lazy(() =>
  import("../Pages/RegisterHouse/RegisterHouse.page")
);
const editionHouse = lazy(() =>
  import("../Pages/RegisterHouse/EditionHouse.page.jsx")
);
const ShowHouse = lazy(() =>
  import("../Pages/RegisterHouse/ShowHouse.page.jsx")
);

// Discount
const DiscountList = lazy(() =>
  import("../Pages/LsitDiscount/ListDiscount.page.jsx")
);
const ShowDiscount = lazy(() =>
  import("../Pages/RegisterDiscont/ShowDiscount.page.jsx")
);
const RegisterDiscount = lazy(() =>
  import("../Pages/RegisterDiscont/RegisterDiscount.page.jsx")
);
const EditionDiscout = lazy(() =>
  import("../Pages/RegisterDiscont/EditionDiscount.page.jsx")
);
const ReservationListItem = lazy(() =>
  import("../Pages/ListReservationItem/ReservationListItem.page.jsx")
);
const ReservationListItemOrdersList = lazy(() =>
  import(
    "../Pages/RegisterReservationItem/ReservationListItemOrdersList.page.jsx"
  )
);
const ReservationListItemDisableTimes = lazy(() =>
  import(
    "../Pages/RegisterReservationItem/ReservationListItemDisableTimes.page.jsx"
  )
);
const RegisterDisableTimePage = lazy(() =>
  import("../Pages/RegisterReservationItem/ManageDisableTime.page.jsx")
);
const ManageCalendar = lazy(() =>
  import("../Pages/RegisterReservationItem/ManageCalendar.page.jsx")
);
const RegisterReservationItem = lazy(() =>
  import("../Pages/RegisterReservationItem/RegisterReservationItem.page.jsx")
);
const EditionReservationItem = lazy(() =>
  import("../Pages/RegisterReservationItem/EditionReservationItem.page.jsx")
);
const EditionDailyDisableTimePage = lazy(() =>
  import("../Pages/RegisterReservationItem/EditionDailyDisableTime.page.jsx")
);
const EditionHourlyDisableTimePage = lazy(() =>
  import("../Pages/RegisterReservationItem/EditionHourlyDisableTime.page.jsx")
);
const EditionMonthlyDisableTimePage = lazy(() =>
  import("../Pages/RegisterReservationItem/EditionMonthlyDisableTime.page.jsx")
);
const EditionYearlyDisableTimePage = lazy(() =>
  import("../Pages/RegisterReservationItem/EditionYearlyDisableTime.page.jsx")
);
const ShowReservationItemPage = lazy(() =>
  import("../Pages/RegisterReservationItem/ShowReservationItem.page.jsx")
);

export const allRoutes = [
  {
    path: "/",
    component: Home,
    idx: true,
  },
  {
    path: "/show-reservation-item/:id",
    component: ShowReservationItemPage,
    idx: true,
  },
  {
    path: "/edit-disable-time-monthly/:id",
    component: EditionMonthlyDisableTimePage,
    idx: true,
  },
  {
    path: "/edit-disable-time-hourly/:id",
    component: EditionHourlyDisableTimePage,
    idx: true,
  },
  {
    path: "/edit-disable-time-yearly/:id",
    component: EditionYearlyDisableTimePage,
    idx: true,
  },
  {
    path: "/edit-disable-time-daily/:id",
    component: EditionDailyDisableTimePage,
    idx: true,
  },
  {
    path: "/edit-reservation-item/:id",
    component: EditionReservationItem,
    idx: true,
  },
  {
    path: "/add-reservation-item",
    component: RegisterReservationItem,
    idx: true,
  },
  {
    path: "/calendar/:type/:id",
    component: ManageCalendar,
    idx: true,
  },
  {
    path: "/add-disable-time/:type",
    component: RegisterDisableTimePage,
    idx: true,
  },
  {
    path: "/reservation-list-item",
    component: ReservationListItem,
    idx: true,
  },
  {
    path: "/reservation-list-item-disable/:type/:id",
    component: ReservationListItemDisableTimes,
    idx: true,
  },
  {
    path: "/reservation-list-item-orders/:id",
    component: ReservationListItemOrdersList,
    idx: true,
  },
  {
    path: "/discount",
    component: DiscountList,
    idx: true,
  },
  {
    path: "/add-discount",
    component: RegisterDiscount,
    idx: true,
  },
  {
    path: "/edit-discount/:id",
    component: EditionDiscout,
    idx: true,
  },
  {
    path: "/see-discount/:id",
    component: ShowDiscount,
    idx: true,
  },
  {
    path: "/users-list",
    component: UsersList,
    idx: true,
  },
  {
    path: "/edit-form/:id",
    component: EditForm,
    idx: true,
  },
  {
    path: "/add-form",
    component: Addform,
    idx: true,
  },
  {
    path: "/deleted-users",
    component: deletedUsersList,
    idx: true,
  },

  {
    path: "/subscriptions-list",
    component: SubscriptionsList,
    idx: true,
  },
  {
    path: "roles-list/create-subscription/:id/:rtl",
    component: PublicSubscription,
    idx: true,
  },

  {
    path: "/subscription",
    component: yourSubscription,
    idx: true,
  },
  {
    path: "/select-packages",
    component: selectPackages,
    idx: true,
  },
  {
    path: "/add-subscription",
    component: RegisterSubscription,
    idx: true,
  },
  {
    path: "/edit-subscription/:id",
    component: EditionSubscription,
    idx: true,
  },
  {
    path: "/see-subscription/:id",
    component: ShowSubscription,
    idx: true,
  },

  /*** contacts ****/
  {
    path: "/contacts-list",
    component: ContactsList,
    idx: true,
  },

  {
    path: "/list-contacts-log",
    component: ContactLog,
    idx: true,
  },

  {
    path: "/deleted-contacts",
    component: deletedContactsList,
    idx: true,
  },
  {
    path: "/favorite-units-contact/:id",
    component: FavoriteUnitContact,
    idx: true,
  },
  {
    path: "/add-contact",
    component: RegisterContact,
    idx: true,
  },
  {
    path: "/edit-contact/:id",
    component: EditionContact,
    idx: true,
  },
  {
    path: "/see-contact/:id",
    component: ShowContact,
    idx: true,
  },

  /*** users ***/
  {
    path: "/list-users-log",
    component: UserLog,
    idx: true,
  },
  {
    path: "/my-profile",
    component: EditionUser2,
    idx: true,
  },
  {
    path: "/add-user",
    component: RegisterUser,
    idx: true,
  },
  {
    path: "/edit-user/:id",
    component: EditionUser,
    idx: true,
  },
  {
    path: "/see-user/:id",
    component: ShowUser,
    idx: true,
  },
  {
    path: "/login-logs-user/:id",
    component: LoginLogsUser,
    idx: true,
  },
  {
    path: "/user-commitions-list/:id",
    component: CommitionsList,
    idx: true,
  },
  {
    path: "/user-contract-list/:id",
    component: UserContractList,
    idx: true,
  },
  {
    path: "/passwords-list/:id",
    component: PasswordsList,
    idx: true,
  },
  {
    path: "/add-password/:id",
    component: RegisterPassword,
    idx: true,
  },
  {
    path: "/add-schedule/:id",
    component: RegisterSchedule,
    idx: true,
  },
  {
    path: "/edit-schedule/:id",
    component: EditSchedule,
    idx: true,
  },

  /*** items ***/

  {
    path: "/units-list",
    component: ItemsList,
    idx: true,
  },
  {
    path: "/units-log-list",
    component: UnitLogList,
    idx: true,
  },
  {
    path: "/deleted-units",
    component: deletedUnitsList,
    idx: true,
  },

  {
    path: "/add-unit",
    component: RegisterItem,
    idx: true,
  },
  {
    path: "/edit-unit/:id",
    component: EditionItem,
    idx: true,
  },
  {
    path: "/see-unit/:id",
    component: ShowItem,
    idx: true,
  },
  {
    path: "/price-chart/:id",
    component: PriceChart,
    idx: true,
  },
  {
    path: "/price-history/:id",
    component: PriceHistoriesList,
    idx: true,
  },
  {
    path: "/price-history/add/:id",
    component: ChangePriceUnit,
    idx: true,
  },
  /*** extra fields ***/
  {
    path: "/extra-fields-list",
    component: ExtraFieldsList,
    idx: true,
  },

  {
    path: "/extra-field/used/list/:id",
    component: UsedsList,
    idx: true,
  },
  {
    path: "/add-extra-field",
    component: RegisterExtraField,
    idx: true,
  },
  {
    path: "/edit-extra-field/:id",
    component: EditionExtraField,
    idx: true,
  },
  {
    path: "/see-extra-field/:id",
    component: ShowExtraField,
    idx: true,
  },

  /*** form maker  ***/

  {
    path: "/form-maker",
    component: formMakerList,
    idx: true,
  },

  /*** packages ***/

  {
    path: "/packages",
    component: PackagesList,
    idx: true,
  },

  {
    path: "/add-package",
    component: RegisterPackage,
    idx: true,
  },
  {
    path: "/add-package-gift",
    component: RegisterGiftesPackage,
    idx: true,
  },
  {
    path: "/edit-package/:id",
    component: EditionPackage,
    idx: true,
  },
  {
    path: "/see-package/:id",
    component: ShowPackage,
    idx: true,
  },

  /*** add house ***/
  {
    path: "/house",
    component: addHouseList,
    idx: true,
  },
  {
    path: "/add-house",
    component: registerHouse,
    idx: true,
  },
  {
    path: "/edit-house/:id",
    component: editionHouse,
    idx: true,
  },
  {
    path: "/see-house/:id",
    component: ShowHouse,
    idx: true,
  },

  /*** types ***/
  {
    path: "/types-list",
    component: TypesList,
    idx: true,
  },
  {
    path: "/add-type",
    component: RegisterType,
    idx: true,
  },
  {
    path: "/edit-type/:id",
    component: EditionType,
    idx: true,
  },
  {
    path: "/see-type/:id",
    component: ShowType,
    idx: true,
  },
  /*** languages ***/
  {
    path: "/languages-list",
    component: LanguagesList,
    idx: true,
  },
  {
    path: "/add-language",
    component: RegisterLanguage,
    idx: true,
  },
  {
    path: "/edit-language/:id",
    component: EditionLanguage,
    idx: true,
  },
  {
    path: "/see-language/:id",
    component: ShowLanguage,
    idx: true,
  },
  /*** categories ***/
  {
    path: "/projects-list",
    component: ProjectsList,
    idx: true,
  },
  {
    path: "/add-project",
    component: RegisterProject,
    idx: true,
  },
  {
    path: "/edit-project/:id",
    component: EditionProject,
    idx: true,
  },
  {
    path: "/see-project/:id",
    component: ShowProject,
    idx: true,
  },

  /*** reservation-list ***/
  {
    path: "/reservation-list",
    component: ReservationList,
    idx: true,
  },
  {
    path: "/reservation-list/:id?",
    component: ReservationUnitList,
    idx: true,
  },

  {
    path: "/see-project/:id",
    component: ShowProject,
    idx: true,
  },
  {
    path: "/project/agent/:id",
    component: ChangeProjectAgent,
    idx: true,
  },

  /*** user-groups-list ***/
  {
    path: "/roles-list",
    component: UserGroupsList,
    idx: true,
  },
  {
    path: "/add-role",
    component: RegisterUserGroup,
    idx: true,
  },
  {
    path: "/edit-role/:id",
    component: EditionRole,
    idx: true,
  },
  {
    path: "/see-role/:id",
    component: ShowRole,
    idx: true,
  },

  /*** contracts ***/

  {
    path: "/contracts-list",
    component: ContractsList,
    idx: true,
  },
  {
    path: "/contracts-list/commissions/:id",
    component: ContractsCommissionsList,
    idx: true,
  },
  {
    path: "/add-reservation",
    component: RegisterReservation,
    idx: true,
  },
  {
    path: "/edit-contact-form/:id",
    component: EditionReservation,
    idx: true,
  },
  {
    path: "/see-contract-form/:id",
    component: ShowContractForm,
    idx: true,
  },
  {
    path: "/add-payment/:id",
    component: RegisterPayment,
    idx: true,
  },
  {
    path: "/add-commition/:id",
    component: RegisterCommitions,
    idx: true,
  },
  {
    path: "/add-contact-form/:id",
    component: RegisterContactForm,
    idx: true,
  },

  /*** list Negotiates ***/
  {
    path: "/negotiates-list/:id?",
    component: NegotiatesList,
    idx: true,
  },
  {
    path: "/negotiates-contact/:id",
    component: NegotiatesContactList,
    idx: true,
  },
  {
    path: "/add-negotiate/:id?",
    component: RegisterNegotiates,
    idx: true,
  },
  {
    path: "/edit-negotiate/:id",
    component: EditionNegotiates,
    idx: true,
  },
  {
    path: "/see-negotiate/:id",
    component: ShowNegotiates,
    idx: true,
  },

  /*** setting ***/
  {
    path: "/setting",
    component: RegisterSetting,
    idx: true,
  },

  /*** FileManager ***/
  {
    path: "/file-manager",
    component: RegisterFile,
    idx: true,
  },
  /*** messages ***/
  {
    path: "/messages-list",
    component: MessagesList,
    idx: true,
  },
  {
    path: "/notifications",
    component: NotificationsList,
    idx: true,
  },
  {
    path: "/contact-notifications/:id",
    component: ContactNotificationList,
    idx: true,
  },
  {
    path: "/add-message",
    component: RegisterMessage,
    idx: true,
  },
  {
    path: "/edit-message/:id",
    component: EditionMessage,
    idx: true,
  },
  {
    path: "/see-message/:id",
    component: ShowMessage,
    idx: true,
  },

  /*** Message Template ***/

  {
    path: "/message-template",
    component: MessageTemplateList,
    idx: true,
  },
  {
    path: "/add-message-template",
    component: RegisterMessageTemplate,
    idx: true,
  },

  {
    path: "/edit-message-template/:id",
    component: EditionMessageTemplate,
    idx: true,
  },

  /*** tickets ***/
  {
    path: "/tickets-list",
    component: TicketsList,
    idx: true,
  },
  {
    path: "/add-ticket",
    component: RegisterTicket,
    idx: true,
  },
  {
    path: "/see-ticket/:id",
    component: ShowTicket,
    idx: true,
  },

  /*** Received Feedbacks ****/
  {
    path: "/received-feedbacks-list",
    component: ReceivedFeedbacksList,
    idx: true,
  },
  {
    path: "/see-received-feedback/:id",
    component: ShowReceivedFeedback,
    idx: true,
  },
  /*** Feedbacks ****/
  {
    path: "/feedbacks-list",
    component: FeedbacksList,
    idx: true,
  },

  {
    path: "/see-feedback/:id",
    component: ShowFeedback,
    idx: true,
  },

  /**** contact us ****/
  {
    path: "/call-me-list",
    component: CallMeList,
    idx: true,
  },
  {
    path: "/see-call-me/:id",
    component: ShowCallMe,
    idx: true,
  },

  /**** contact us ****/
  {
    path: "/contact-us-list",
    component: ContactUsList,
    idx: true,
  },
  {
    path: "/see-contact-us/:id",
    component: ShowContactUs,
    idx: true,
  },

  /*** Invoices ****/
  {
    path: "/invoices-list",
    component: InvoicesList,
    idx: true,
  },
  {
    path: "/visit-request-list",
    component: VisitRequestsList,
    idx: true,
  },
  {
    path: "/add-invoice",
    component: RegisterInvoice,
    idx: true,
  },
  {
    path: "/edit-invoice/:id",
    component: EditionInvoice,
    idx: true,
  },
  {
    path: "/see-invoice/:id",
    component: ShowInvoice,
    idx: true,
  },

  /*** contact and item ****/
  {
    path: "/contact-and-items-list",
    component: ContactAndItemsList,
    idx: true,
  },
  {
    path: "/add-contact-and-item",
    component: RegisterContactAndItem,
    idx: true,
  },
  {
    path: "Kanban",
    component: KanbanBoard,
    idx: true,
  },
];
