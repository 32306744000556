// ModalMessageSuccess.jsx
import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  Autocomplete,
} from "@mui/material";
import PropTypes from "prop-types";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const ModalMessageSuccess = ({ open, handleClose, message }) => {
  // const handleCloseWindow = () => {
  //   window.close();
  // };
  return (
    <Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "20%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          height: "90vh",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: "8px",
          overflowY: "auto",
          maxHeight: "20vh",
        }}
      >
        {/* محتوا داخل مدال */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1, // فاصله بین آیکون و پیام
            mb: 2, // فاصله پایین از سایر محتوا
          }}
        >
          <CheckCircleOutlineIcon sx={{ color: "#00ff00", fontSize: 50 }} />
          <Typography variant="h4">{message}</Typography> {/* پیام */}
        </Box>

        {/* سایر محتوای مدال */}
        <Button onClick={handleClose} variant="contained">
          Close
        </Button>
      </Box>
    </Modal>
  );
};

ModalMessageSuccess.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};

export default ModalMessageSuccess;
