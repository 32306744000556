import React, { Fragment, useEffect, useLayoutEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import Container from "@mui/material/Container";
import { Fields, FormLabel, InputRow } from "./FormStyles/seeForm.style";
import { Controller, useForm } from "react-hook-form";
// import Fields from "../../components/Inputs/Fields";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  TextField,
} from "@mui/material";
import { PageContainer } from "../pagesStyles/pages.styles";
import MuiPhoneNumber from "mui-phone-number";
import FormRow from "../../components/Inputs/Row";
import StrinLocalized from "../../Language/language.lang";
import { AiOutlinePhone } from "react-icons/ai";
import { MdOutlineMail } from "react-icons/md";
import { FaListCheck } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router";
import { getAll } from "../../Utils/getAll";
import DateMange from "../../components/datePicker/DateMange";
import dataStatic from "../../data/feilds";
import axiosInstance from "../../HelperAxios/axios";
import { toast, ToastContainer } from "react-toastify";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import ExtraFields from "./ExtraField";
import FormMakerFooter from "./Footer/FormMakerFooter.component";
import axios from "axios";
import strings from "../../Language/language.lang";
import ModalMessageSuccess from "./ModalSuccess.modal";

const listOfLanguages = [
  { alias: "EN", name: "English" },
  { alias: "ES", name: "Spanish" },
  { alias: "FR", name: "French" },
  { alias: "DE", name: "German" },
  { alias: "IT", name: "Italian" },
  { alias: "PT", name: "Portuguese" },
  { alias: "RU", name: "Russian" },
  { alias: "ZH", name: "Chinese" },
  { alias: "JA", name: "Japanese" },
  { alias: "KO", name: "Korean" },
  { alias: "AR", name: "Arabic" },
  { alias: "HI", name: "Hindi" },
  { alias: "BN", name: "Bengali" },
  { alias: "PA", name: "Punjabi" },
  { alias: "UR", name: "Urdu" },
  { alias: "VI", name: "Vietnamese" },
  { alias: "TH", name: "Thai" },
  { alias: "PL", name: "Polish" },
  { alias: "NL", name: "Dutch" },
  { alias: "SV", name: "Swedish" },
  { alias: "FI", name: "Finnish" },
  { alias: "NO", name: "Norwegian" },
  { alias: "DA", name: "Danish" },
  { alias: "HE", name: "Hebrew" },
  { alias: "TR", name: "Turkish" },
  { alias: "EL", name: "Greek" },
  { alias: "HU", name: "Hungarian" },
  { alias: "CS", name: "Czech" },
  { alias: "SK", name: "Slovak" },
  { alias: "RO", name: "Romanian" },
  { alias: "BG", name: "Bulgarian" },
  { alias: "SR", name: "Serbian" },
  { alias: "HR", name: "Croatian" },
  { alias: "UK", name: "Ukrainian" },
  { alias: "ID", name: "Indonesian" },
  { alias: "MS", name: "Malay" },
  { alias: "TL", name: "Tagalog" },
  { alias: "FA", name: "Persian" },
];

const SeeFormPage = () => {
  const [languages, setLanguages] = useState([]);
  const { handleSubmit, control, watch } = useForm();
  const fullUrl = window.location.href;
  const [url, setUrl] = useState(fullUrl);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [validBtn, setValidBtn] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [form, setForm] = useState([]);
  const { id, rtl, language } = useParams();
  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };
  useEffect(() => {
    getAll(setLanguages, "/language/list-default-languages?platform=Portal");
  }, [rtl]);
  let languageOptions = [];
  if (languages) {
    languages.map((item) => {
      languageOptions.push({
        value: item.id,
        label: listOfLanguages.find((i) => i.alias == item.alias).name,
      });
    });
  }
  const lang = localStorage.getItem("lang");
  lang && StrinLocalized.setLanguage(lang.toUpperCase());
  useEffect(() => {
    if (languages.length > 0) {
      axiosInstance
        .get(`public/${languages.find((i) => i.alias == language).file}`)
        .then((file) => {
          if (file) {
            const fileObj = file?.data;
            const obj = { [lang]: fileObj };

            strings.setContent(obj);
            setShouldUpdate((prev) => !prev);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [languages]);

  useLayoutEffect(() => {
    getAll(setForm, `/contact-form/get/public/form/${id}`);
  }, [rtl]);

  useEffect(() => {
    if (form) {
      document.title = `${form?.data?.page_title}`;
    } else {
      document.title = StrinLocalized.ShowForm;
    }
  }, [form]);
  const extra = [];
  const allUser = [];
  const filteredObjects = dataStatic.filter((obj) =>
    form?.data?.fields.includes(obj.id)
  );
  console.log(filteredObjects);

  const handleOptions = (option) => {
    const newOption = [];
    option?.map((item) => {
      newOption.push({ value: item.id, label: item.name });
    });
    return newOption;
  };

  const fields = {
    ShortString: (e) => (
      <Controller
        name={e.name}
        control={control}
        render={({ field: { onChange, value = "" } }) => (
          <TextField
            fullWidth
            value={value}
            placeholder={e.description}
            onChange={onChange}
            variant="outlined"
            InputProps={{ style: { height: 60, borderRadius: 10 } }}
            size="small"
          />
        )}
      />
    ),
    Address: (e) => (
      <Controller
        name={e.name}
        control={control}
        render={({ field: { onChange, value = "" } }) => (
          <TextField
            fullWidth
            value={value}
            placeholder={e.description}
            onChange={onChange}
            variant="outlined"
            InputProps={{ style: { height: 60, borderRadius: 10 } }}
            size="small"
          />
        )}
      />
    ),
    LongString: (e) => (
      <Controller
        name={e.name}
        control={control}
        render={({ field: { onChange, value = "" } }) => (
          <TextField
            fullWidth
            multiline
            rows={4}
            value={value}
            placeholder={e.description}
            onChange={onChange}
            variant="outlined"
            InputProps={{ style: { borderRadius: 10 } }}
            size="small"
          />
        )}
      />
    ),
    Date: (e) => (
      <Controller
        name={e.name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <DateMange label={e.description} value={value} onChange={onChange} />
        )}
      />
    ),
    Number: (e) => (
      <Controller
        name={e.name}
        control={control}
        render={({ field: { onChange, value = "" } }) => (
          <TextField
            fullWidth
            value={value}
            type="number"
            placeholder={e.description}
            onChange={onChange}
            variant="outlined"
            InputProps={{ style: { height: 60, borderRadius: 10 } }}
            size="small"
          />
        )}
      />
    ),
    Price: (e) => (
      <Controller
        name={e.name}
        control={control}
        render={({ field: { onChange, value = "" } }) => (
          <TextField
            fullWidth
            value={value}
            type="number"
            placeholder={e.description}
            onChange={onChange}
            variant="outlined"
            InputProps={{ style: { height: 60, borderRadius: 10 } }}
            size="small"
          />
        )}
      />
    ),
    CheckBox: (e) => (
      <Controller
        name={e.name}
        control={control}
        render={({ field: { onChange, value = false } }) => (
          <Checkbox checked={value} onChange={onChange} />
        )}
      />
    ),
    Tell: (e) => (
      <Controller
        name={e.name}
        control={control}
        render={({ field: { onChange, value = "" } }) => (
          <MuiPhoneNumber
            fullWidth
            defaultCountry={"ae"}
            onChange={onChange}
            value={value}
            variant="outlined"
            size="small"
            InputProps={{
              style: { height: 60, borderRadius: 10 },
            }}
            // label={`${StrinLocalized.Tell} *`}
          />
        )}
      />
    ),
    MultiSelect: (e) => (
      <Controller
        name={e.name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            multiple
            disablePortal
            fullWidth
            // disabled={watchAllcontacts[0] ? true : false}
            id="combo-box-demo"
            options={handleOptions(e?.optionsField)}
            // options={e.options}
            value={value}
            size="small"
            onChange={(e, data) => onChange(data)}
            renderInput={(params) => (
              <TextField
                {...params}
                // label={StrinLocalized.FormFields}
                placeholder={e.description}
                InputProps={{
                  ...params.InputProps,
                  style: { height: 60, borderRadius: 10 },
                }}
              />
            )}
          />
        )}
      />
    ),
    OneSelect: (e) => (
      <Controller
        name={e.name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            disablePortal
            fullWidth
            // disabled={watchAllcontacts[0] ? true : false}
            id="combo-box-demo"
            // options={e.options}
            options={handleOptions(e?.optionsField)}
            value={value}
            size="small"
            onChange={(e, data) => onChange(data)}
            renderInput={(params) => (
              <TextField
                {...params}
                // label={StrinLocalized.FormFields}
                placeholder={e.description}
                InputProps={{
                  ...params.InputProps,
                  style: { height: 60, borderRadius: 10 },
                }}
              />
            )}
          />
        )}
      />
    ),
  };

  const onSubmit = (data) => {
    setValidBtn(true);
    let mobile;
    if (data?.tell) {
      mobile = data?.tell.replace(/[\s()-]/g, "");
    }
    const getExtraFieldsValues = () => {
      const extraFieldsObj = {};
      if (form) {
        form.data.extra_fields.map((item) => {
          let objectToAdd;
          if (item.type == "Date" && data[`extra_${item.name}`]) {
            objectToAdd = {
              [item.name]: new Date(data[`extra_${item.name}`]).toISOString(),
            };
          } else if (item.type == "CheckBox" && data[`extra_${item.name}`]) {
            objectToAdd = {
              [item.name]: data[`extra_${item.name}`].toString(),
            };
          } else if (item.type == "Tell" && data[`extra_${item.name}`]) {
            objectToAdd = {
              [item.name]: data[`extra_${item.name}`].replace(/[\s()-]/g, ""),
            };
          } else if (item.type == "MultiSelect") {
            const options = [];
            if (data[`extra_${item.name}`]) {
              data[`extra_${item.name}`].map((opt) => options.push(opt.label));
            }
            objectToAdd = {
              [item.name]: options?.length > 0 ? options : undefined,
            };
          } else if (item.type == "OneSelect") {
            objectToAdd = {
              [item.name]: data[`extra_${item.name}`]?.label.toString(),
            };
          } else if (item.type == "User" || item.type == "Contact") {
            objectToAdd = {
              [item.name]:
                data[`extra_${item.name}`]?.value > 0
                  ? [data[`extra_${item.name}`]?.value]
                  : undefined,
            };
          } else {
            objectToAdd = {
              [item.name]: data[`extra_${item.name}`],
            };
          }
          Object.assign(extraFieldsObj, objectToAdd);
        });
      }
      return extraFieldsObj;
    };
    const extraFieldValues = getExtraFieldsValues();
    const Data = {
      name: data?.name,
      type: "Form",
      email: data?.email,
      description: data?.description,
      subject: form?.data?.name,
      subscription: form.data.subscription.id,
      // file: "file .....",
      extra_fields: {
        phone: mobile,
        language: data?.languages_spoken_proficiently?.value,
        link: url,
        // unitType: "2_bedroom",
        company: data?.company_name,
        emirate: data?.emirate_id,
        number: data?.number,
        typeBusiness: data?.type_of_business?.value,
        website: data?.website,
        passport: data?.passport_number,
        // floor: 5,
        // unit: "asdasd",
      },
      // typeForm: 31,
      extra_field: extraFieldValues,
    };
    console.log(Data);
    toast.info(StrinLocalized.SendingData);
    axios
      .post("https://erptestapi.akafweb.com/contact-form/create", Data, {
        headers: {
          "x-api-key": "58b53900-9ab8-4367-b328-4a17f6818833",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        // toast.success(res.statusText);
        setMessage(res.statusText);
        console.log(res);
        setTimeout(() => {
          handleOpen();
        }, 2000);
      })
      .catch((err) => {
        setTimeout(() => {
          setValidBtn(false);
        }, 1000);
        console.log(err);
        err.response.data.message.map((e) => {
          toast.error(e);
          console.log(e);
        });
        // if (typeof err.response.data.message === "string") {
        //   toast.error(err.response.data.message);
        // } else {
        //   toast.error(err.response.data.message[0]);
        // }
      });
  };
  const currentUser = useAuthUser();
  return (
    <>
      <div
        style={{
          direction: rtl == "true" ? "rtl" : "ltr",
          textAlign: rtl == "true" ? "right" : "left",
        }}
      >
        {form.data ? (
          <div className="w-full flex flex-col justify-center items-center">
            <div className="w-full relative">
              <img
                src={
                  form?.data?.background_image_link
                    ? form?.data?.background_image_link
                    : "/new.png"
                }
                alt=""
                className="w-full h-[500px] bg-cover"
              />
              <div className="absolute gap-2 flex flex-col max-lg:w-60  justify-center items-start max-md:left-[30px] max-lg:left-[100px] lg:left-[400px] top-40">
                <span className="lg:text-4xl max-lg:text-2xl w-full">
                  {form?.data?.header_title}
                </span>
                <span className="text-xs w-full">
                  {form?.data?.header_subtitle}
                </span>
                <div className="flex gap-4 w-full justify-end">
                  <a
                    className="border  rounded-full w-20 h-20 border-red-500 flex justify-center items-center"
                    href={`tel:${form?.data?.tell}`}
                  >
                    <span className="text-red-500">
                      <AiOutlinePhone className="w-12 h-12 " />
                    </span>
                  </a>
                  <a
                    className="border  rounded-full w-20 h-20 border-red-500 flex justify-center items-center"
                    href={form?.data?.site_link}
                  >
                    <span className="text-red-500">
                      <FaListCheck className="w-12 h-12" />
                    </span>
                  </a>
                  <a
                    className="border  rounded-full w-20 h-20 border-red-500 flex justify-center items-center"
                    href={`mailto:${form?.data?.email}`}
                  >
                    <span className="text-red-500">
                      <MdOutlineMail className="w-12 h-12" />
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="container w-10/12 flex flex-col justify-start mb-40">
              <span className="text-3xl m-5">{form?.data?.form_title}</span>
              <PageContainer maxWidth="xlg">
                {/* {status && extraFields ? ( */}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Fields>
                    {filteredObjects?.map((e, i) => (
                      <InputRow key={i}>
                        <FormLabel>{e.label}</FormLabel>
                        {fields[e.type] ? fields[e.type](e) : null}
                      </InputRow>
                    ))}
                    {/* {form?.data?.extra_fields?.map((e, i) => (
                <InputRow key={i}>
                  <FormLabel>{e.name}</FormLabel>
                  {fields[e.type] ? fields[e.type](e) : null}
                </InputRow>
              ))} */}
                    <ExtraFields
                      data={form?.data?.extra_fields}
                      control={control}
                    />
                    <Button
                      type="submit"
                      disabled={validBtn}
                      variant="contained"
                      fullWidth
                    >
                      {StrinLocalized.Submit}
                    </Button>
                  </Fields>
                </form>
              </PageContainer>
            </div>
            <FormMakerFooter data={form?.data} />
          </div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <CircularProgress />
            </div>
          </>
        )}
        <ToastContainer />
      </div>
      <ModalMessageSuccess
        handleClose={handleClose}
        open={modalOpen}
        message={message}
      />
    </>
  );
};

export default SeeFormPage;
